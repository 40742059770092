import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";

function CustomPaper({ children }) {
  return (
    <Paper elevation={8} className="auto-complete-paper">
      {children}
    </Paper>
  );
}

function RenderOption({ option, props, isSelected, leftSlot, rightSlot }) {
  return (
    <li
      {...props}
      key={option.value}
      style={{
        backgroundColor: isSelected ? "var(--color-surface-primary-pressed)" : "",
      }}
    >
      {leftSlot}
      <span className="menu-item">{option.label}</span>
      {rightSlot}
    </li>
  );
}

function SearchableAutoComplete({
  name,
  label,
  helperText,
  options,
  isOptional,
  placeholder,
  value: propValue,
  onChange: propOnChange,
  error: propError,
  size = "small",
  variant = "outlined",
  disabled = false,
  leftSlot = null,
  rightSlot = null,
  ...other
}) {
  const formContext = useFormContext();
  const [open, setOpen] = useState(false);

  const renderSearchableAutoComplete = (field = {}, fieldError) => {
    const handleChange = (event, newValue) => {
      const selectedValue = newValue ? newValue.value : null;
      field.onChange(selectedValue);
      if (propOnChange) {
        propOnChange(selectedValue);
      }
    };

    const selectedOption = options.find((option) => option.value === field.value) || null;

    return (
      <FormControl fullWidth data-testid="form-control">
        <InputLabel
          shrink
          htmlFor={name}
          className="paragraph text-left text-md font-medium line-height-md mb-025 pos-relative non-transform"
        >
          {`${label} ${isOptional ? "(optional)" : ""}`}
        </InputLabel>
        <Autocomplete
          id={name}
          options={options}
          disabled={disabled}
          value={selectedOption}
          onChange={handleChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!fieldError}
              helperText={fieldError ? fieldError.message : helperText}
              placeholder={placeholder}
              size={size}
              variant={variant}
              disabled={disabled}
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: `custom-text-input-root text-left text-field-padding text-field-input-${size} ${
                    disabled ? "disabled-input" : ""
                  }`,
                  input: "paragraph font-regular text-lg line-height-xl flex mr-15",
                  focused: "custom-text-input-focused",
                  error: "custom-text-input-error",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              FormHelperTextProps={{
                classes: {
                  root: "paragraph text-sm font-regular line-height-sm error-text-color ml-0",
                },
              }}
            />
          )}
          PaperComponent={CustomPaper}
          renderOption={(props, option) => (
            <RenderOption
              option={option}
              props={props}
              leftSlot={leftSlot}
              rightSlot={rightSlot}
              isSelected={selectedOption?.value === option.value}
            />
          )}
          {...other}
        />
      </FormControl>
    );
  };

  return formContext && !propValue && !propOnChange ? (
    <Controller
      name={name}
      control={formContext.control}
      defaultValue={null}
      render={({ field, fieldState: { error } }) => renderSearchableAutoComplete(field, error)}
    />
  ) : (
    renderSearchableAutoComplete(
      { value: propValue, onChange: propOnChange },
      propError ? { message: helperText } : null
    )
  );
}

SearchableAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  disabled: PropTypes.bool,
  isOptional: PropTypes.bool,
  leftSlot: PropTypes.node,
  rightSlot: PropTypes.node,
};

SearchableAutoComplete.defaultProps = {
  helperText: "",
  placeholder: "Search and select",
  value: null,
  onChange: undefined,
  error: false,
  size: "small",
  variant: "outlined",
  disabled: false,
  isOptional: false,
  leftSlot: null,
  rightSlot: null,
};

export default SearchableAutoComplete;
