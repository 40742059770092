import React, { useState } from "react";
import { useDispatch } from "react-redux";

import CustomModal from "components/common/Modal";
import Button from "components/common/Button";
import { AlertType } from "components/common/Snackbar";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { unassignEmployeeFromACustomer } from "services/Admin/Dashboard";

import { showSnackbar } from "store/actions/Utility";

function UnassignedEmployeeModal({ name, companyName, open, closeModal, employeeId }) {
  const dispatch = useDispatch();

  const [unassigningEmployee, setUnassigningEmployee] = useState(false);

  const handleEmployeeUnassignment = async () => {
    setUnassigningEmployee(true);
    try {
      await unassignEmployeeFromACustomer(employeeId);
      dispatch(
        showSnackbar({
          type: AlertType.success,
          message: "Employee unassigned successfully",
        })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: "An Error Occured",
        })
      );
    }
    setUnassigningEmployee(false);
    closeModal();
  };

  return (
    <CustomModal open={open} onClose={closeModal} width="474px">
      <div>
        <div className="failure-warning-icon">
          <WarningAmberIcon />
        </div>

        <div className="heading-h2-semibold pt-1">Unassign Employee</div>

        <div>
          <span className="para-body-m-regular pt-025">Are you sure you want to unassign</span>{" "}
          <span className="para-body-m-semibold employee-success-name capitalize">{name}</span>{" "}
          <span className="para-body-m-regular pt-025">from</span>{" "}
          <span className="para-body-m-semibold employee-success-name capitalize">{companyName}</span>{" "}
          <span className="para-body-m-regular pt-025">? This will also remove their salary details.</span>
        </div>

        <div className="float-right flex gap-05 pt-20p ">
          <Button label="Cancel" color="inherit-text" variant="outlined" onClick={closeModal} />
          <Button
            label="Unassign Now"
            isLoading={unassigningEmployee}
            variant="contained"
            color="error"
            onClick={handleEmployeeUnassignment}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default UnassignedEmployeeModal;
