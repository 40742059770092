import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import EditRoundedIcon from "@mui/icons-material/EditRounded";

function Edit(props) {
  const navigate = useNavigate();
  const { selectedId, navigateTo } = props;

  const navigateToEditPage = () => {
    if (selectedId != undefined && navigateTo != undefined) navigate(`/main/details/${selectedId}`);
  };

  return (
    <Tooltip title="Edit">
      <IconButton
        size="small"
        id="edit-emp"
        sx={{ "&:hover": { backgroundColor: "white" } }}
        onClick={navigateToEditPage}
      >
        <EditRoundedIcon fontSize="small" color="muted" sx={{ "&:hover": { color: "#1161D5" } }} />
      </IconButton>
    </Tooltip>
  );
}

export default Edit;
