import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import PageLoader from "components/common/PageLoader/index";
import { convertDate, getValue } from "components/Screens/CommonUtils/index";
import Button from "components/common/Button";
import UnassignedEmployeeModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo/UnassignEmployee";
import AssignToCustomerModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo/AssignToCustomer";

import { getEmploymentDetailsForAdmin } from "services/Admin/Dashboard";

import "./EmploymentInfo.scss";

function UnAssignedEmployee({ onAssignToCustomerBtnClick }) {
  return (
    <div className="unassigned-employee-container">
      <WorkOutlineIcon />
      <div className="para-body-m-regular mb-05">This employee is not currently assigned to a customer.</div>
      <Button label="Assign to Customer" size="small" onClick={onAssignToCustomerBtnClick} variant="outlined" />
    </div>
  );
}

function EmploymentInfo({ initialPersonalDataEmployment }) {
  const rowClassesForPersonalInfo = "flex justify-between w-100p mb-1";

  const [showAssignToCustomerModal, setShowAssignToCustomerModal] = useState();
  const [showUnassignmentModal, setShowUnassignmentModal] = useState(false);
  const unassignedEmployee = false;

  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);
  const {
    dateOfBirth,
    country,
    gender,
    cnic_dni_cex: nationalID,
    companyName,
    firstName,
    middleName,
    lastName,
  } = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails)?.payload || {};

  const fetchEmploymentDetails = async () => {
    await getEmploymentDetailsForAdmin(initialPersonalDataEmployment?.employeeId);
  };

  useEffect(() => {
    fetchEmploymentDetails();
  }, []);

  return (
    <>
      {loadingGetEmployeeDetails && <PageLoader />}

      <AssignToCustomerModal
        employeeId={initialPersonalDataEmployment?.employeeId}
        open={showAssignToCustomerModal}
        closeModal={() => setShowAssignToCustomerModal(false)}
      />

      <UnassignedEmployeeModal
        employeeId={initialPersonalDataEmployment?.employeeId}
        companyName={companyName}
        name={`${firstName} ${middleName || ""} ${lastName}`}
        open={showUnassignmentModal}
        closeModal={() => setShowUnassignmentModal(false)}
      />

      <div className={rowClassesForPersonalInfo.replace("mb-1", "mb-15")}>
        <div className="flex items-center gap-05">
          <WorkOutlineIcon className="color-icon-primary" alt="work" />
          <div className="heading-h4-semibold">Employment</div>
        </div>

        {!unassignedEmployee && (
          <div className="flex gap-1">
            <Button
              label="Un-assign From Customer"
              size="small"
              onClick={() => setShowUnassignmentModal(true)}
              variant="outlined"
            />
            <Button
              label="Edit"
              size="small"
              onClick={() => setShowAssignToCustomerModal(true)}
              variant="outlined"
              startIcon={<EditOutlinedIcon />}
            />
          </div>
        )}
      </div>

      {unassignedEmployee ? (
        <UnAssignedEmployee onAssignToCustomerBtnClick={() => setShowAssignToCustomerModal(true)} />
      ) : (
        <Grid item container direction="column" rowSpacing={1.5} xs={12}>
          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Company Name</div>
            <div className="para-body-m-semibold">{getValue(country)}</div>
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Department</div>
            <div className="para-body-m-semibold">{getValue(nationalID)}</div>
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Manager</div>
            <div className="para-body-m-semibold">{convertDate(dateOfBirth)}</div>
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Job Title</div>
            <div className="para-body-m-semibold">{convertDate(dateOfBirth)}</div>
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Hired Date</div>
            <div className="para-body-m-semibold">{convertDate(dateOfBirth)}</div>
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Company Address</div>
            <div className="para-body-m-semibold">{convertDate(dateOfBirth)}</div>
          </div>

          <div className={rowClassesForPersonalInfo.replace("mb-1", "")}>
            <div className="heading-h5-regular">Edge Wallet</div>
            <div className="para-body-m-semibold">{getValue(gender)}</div>
          </div>
        </Grid>
      )}
    </>
  );
}

export default EmploymentInfo;
