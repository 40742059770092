import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Grid, Stack, Tab, Tabs, IconButton } from "@mui/material";
import { Helmet } from "react-helmet";

import PageLoader from "components/common/PageLoader";
import Overview from "components/Screens/Workforce/UserDetails/NewPersonalInfo";
import Contact from "components/Widgets/BambooHRTabs/Contact";
import SalaryDetails from "components/Widgets/BambooHRTabs/SalaryDetails";
import EmployeeEducation from "components/Widgets/BambooHRTabs/Education";
import EmployeeExperience from "components/Widgets/BambooHRTabs/Experience";
import EmployeeDocs from "components/Widgets/BambooHRTabs/Documents";
import Notes from "components/Screens/Workforce/UserDetailsV2/Notes";
import EmployeeTimeOffDetails from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails";
import BackIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/BackIcon";
import EmployeeInfoTop from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeoffNewActivity/EmployeeInfoTop";

import { employeeSalaryDetails } from "store/actions/Workforce/EmployeeDetils/SalaryDetails";
import { employeeSalaryInfo } from "store/actions/Workforce/EmployeeDetils/SalaryInfo";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";

import { checkUserAdditionalInfo, getValue } from "components/Screens/CommonUtils";
import { ADMIN_WORKFORCE_TABS } from "constants";
import { baseUrl } from "util/APIBaseUrl";

import "./user-details.scss";

function Index() {
  const dispatch = useDispatch();
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const tab = urlParams.get("tab");
  const navigateFrom = urlParams.get("navigateFrom");
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const [currentTab, setCurrentTab] = useState("Overview");
  const [, setUserData] = useState({});
  const [tabs, setTabs] = useState(ADMIN_WORKFORCE_TABS);

  const getEmployeeDetails = useSelector((userDetails) => userDetails.GetEmployeeDetails.getEmployeeDetails);
  const LOADING_GET_EMPLOYEE_DETAILS = useSelector(
    (userDetails) => userDetails.GetEmployeeDetails.loadingGET_EMPLOYEE_Details
  );
  const { userAdditionalInfo, loadingAdditionalInfo } = useSelector((userDetails) => userDetails.UserAdditionalInfo);
  const { loadingEmployeeSalaryInfo } = useSelector((userDetails) => userDetails.SalaryInfo);

  const {
    payload: {
      employeeId,
      firstName,
      lastName,
      middleName,
      jobTitle,
      timeoffFlag,
      customerId,
      pictureVersion,
      edgeJoiningDate,
      hrpoc,
    } = {},
  } = getEmployeeDetails;

  const handleChange = (event, newValue) => {
    window.location.hash = newValue;
    setCurrentTab(newValue);
  };

  const handleBackButton = () => {
    if (navigateFrom) {
      return navigate(`/main/${navigateFrom}`);
    }
    return navigate("/main/workforce");
  };

  useEffect(() => {
    let employeeSalaryDetailsRequest = "";
    let employeeSalaryInfoRequest = "";

    if (Object.keys(loggedInUser).length && loggedInUser.userId && getEmployeeDetails?.ok) {
      if (loggedInUser?.type == "customer") {
        employeeSalaryInfoRequest = `customer/${customerId}/employee/${employeeId}/salaryInfo`;
        employeeSalaryDetailsRequest = `customer/${customerId}/employee/${employeeId}/payroll`;
      } else if (loggedInUser?.type == "edge") {
        employeeSalaryInfoRequest = `employee/${employeeId}/salary`;
        employeeSalaryDetailsRequest = `employee/${employeeId}/payroll`;
      }

      dispatch(
        employeeSalaryDetails({
          request: employeeSalaryDetailsRequest,
        })
      );

      dispatch(
        employeeSalaryInfo({
          request: employeeSalaryInfoRequest,
        })
      );

      if (timeoffFlag && customerId && !tabs?.find((tabData) => tabData?.title === "Time-off")) {
        setTabs([...tabs, { title: "Time-off", link: "" }]);
      }
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    setUserData(loggedInUser);

    if (
      (Object.keys(loggedInUser).length &&
        loggedInUser.userId &&
        !Object.keys(getEmployeeDetails).length &&
        params.id &&
        checkUserAdditionalInfo(userAdditionalInfo)) ||
      state?.redirectFrom == "editEmployee"
    ) {
      if (loggedInUser?.type == "customer") {
        dispatch(
          GetEmployeeDetails({
            customerID: loggedInUser?.userId,
            employeeID: params.id,
            request: `customer/${userAdditionalInfo?.customerId}/employee/${params.id}`,
          })
        );
      } else if (loggedInUser?.type == "edge") {
        dispatch(
          GetEmployeeDetails({
            customerID: loggedInUser?.userId,
            employeeID: params.id,
            request: `employee/${params.id}`,
          })
        );
      }
    }
  }, [userAdditionalInfo]);

  useEffect(() => {
    if (tab) setCurrentTab(tab);
  }, [tab]);

  const renderTabContent = () => {
    switch (currentTab) {
      case "Overview":
        return <Overview />;
      case "Contact":
        return (
          <div className="admin-contact-wrapper">
            <Contact />
          </div>
        );
      case "Education":
        return <EmployeeEducation employeeId={employeeId} />;
      case "Salary Details":
        return <SalaryDetails />;
      case "Documents":
        return <EmployeeDocs />;
      case "Experience":
        return <EmployeeExperience employeeId={employeeId} />;
      case "Notes":
        return <Notes />;
      case "Time-off":
        return <EmployeeTimeOffDetails />;
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Edge | Employees - ${`${getValue(firstName)} ${getValue(lastName)}`}`}</title>
      </Helmet>
      {LOADING_GET_EMPLOYEE_DETAILS || loadingAdditionalInfo || loadingEmployeeSalaryInfo ? <PageLoader /> : null}
      <div className="manage-timeoff-wrapper-admin">
        <Stack direction="row" alignItems="center" sx={{ mb: "12px", padding: "16px 32px" }}>
          <IconButton onClick={() => handleBackButton()}>
            <BackIcon />
          </IconButton>
          <EmployeeInfoTop
            img={`${baseUrl}/employee/${employeeId}/logo?id=${pictureVersion}&variant=thumbnail`}
            name={`${getValue(firstName, "")} ${getValue(middleName, "")} ${getValue(lastName, "")}`}
            jobTitle={getValue(jobTitle)}
            managedBy={`${getValue(hrpoc?.First_Name)} ${getValue(hrpoc?.Last_Name, "")}`}
            hiredDate={edgeJoiningDate}
          />
        </Stack>

        <div className="tabs-container">
          <Tabs
            value={currentTab}
            onChange={handleChange}
            sx={{
              ".MuiTabs-indicator": {
                height: "4px !important",
                zIndex: 2,
              },
              ".MuiTabs-flexContainer": {
                gap: "16px",
              },
              ".Mui-selected": {
                color: "#212231",
              },

              "& .MuiTabs-scroller": {
                paddingLeft: "24px",
              },
            }}
          >
            {tabs?.map((tabData) => (
              <Tab label={tabData?.title} value={tabData?.title} key={tabData} disableRipple className="holidays-tab" />
            ))}
          </Tabs>
          <span
            style={{
              display: "block",
              width: "100%",
              height: "1px",
              backgroundColor: "#B2B4E2",
              position: "absolute",
              bottom: "2px",
            }}
          />
        </div>
      </div>
      <Grid
        container
        direction="row"
        id="employeeInformation"
        className="custom-scroll"
        sx={{
          maxHeight: "calc(100vh - 260px)",
          overflowY: currentTab == "Time-off" ? "clip" : "auto",
          paddingRight: "0px !important",
        }}
      >
        <div
          className={`w-full ${currentTab !== "Documents" && currentTab !== "Notes" && currentTab !== "Salary Details" ? "pt-175 pb-15 px-2" : ""} bg-white`}
        >
          {renderTabContent()}
        </div>
      </Grid>
    </>
  );
}

export default Index;
