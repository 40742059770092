import { makeRequest } from "./APIHelper";
// import { useDispatch } from "react-redux";
// import { RegisterUser } from "../store/actions/RegisterUser";
// FOR POST REQUEST
const postRequest = async (path, body, loaderFlag, method = "POST", contentType, apiType) => {
  // const dispatch = useDispatch();
  const options = {
    path,
    method,
    body,
    contentType: contentType || "application/json",
  };
  // Return the fetch response or throw the error.
  // dispatch(RegisterUser());
  // eslint-disable-next-line no-return-await
  return await makeRequest(options, loaderFlag != undefined ? loaderFlag : true, apiType);
};
export default postRequest;
